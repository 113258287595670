/*********************************
*********** + Корзина ************
**********************************/

var cart = new Cart();

function shop () {
    shop.$orderTable    = $('.cart__box');
    shop.$total         = $('.cart__total span, .cart__flex__total');
    shop.$orderTotal    = $('#orderTotal');
    shop.$totalQuantity = $('#totalQuantity');
    shop.$headCart      = $('.head__cart');
    // shop.popup          = $('.popupAddCart');
    shop.closeBut       = $('.addProdPopup__close, #backCatalog');
    shop.fixCart        = $('.addProdPopup');
    shop.winProduct     = $('.addProdPopup__item');
    shop.$delivInput    = $('[name=delivery]');
    shop.deliveryPrice  = 0;
    

    shop.currency = {
        //basic: $('meta[name="currency-basic"]').attr('content'),
        name: $('meta[name="currency-name"]').attr('content'),
    }
    shop.$srcCart       = $('meta[name="cart-url"]').attr('content');

    shop.$pageOptionCart = $('body').hasClass('option-cart') ? 1 : 0;
    shop.$pageOptionItem = $('body').hasClass('option-catalog-item') ? 1 : 0;

    shop.buy();
    shop.request();
    shop.valueTest();
    shop.number();
    shop.delProdInCart();
    shop.closeWindow();
    // shop.hideProdPopup();
    //shop.clearAllCart();
    //shop.getDiscount();
    //shop.changeDelivery();
    if (shop.$pageOptionCart) shop.order();
}

shop.number = function () {
    var timeout;

    function setTotal (data, row) {
        shop.$total.each(function() {
            $(this).text(numberFormat(data.total + ' ' + shop.currency.name, {decimals: 0, thousands_sep: ' '}));
        });
        for (var i in data.cart) {
            if (data.cart[i].id == row.data('id')) {
                var sum = cart.calc(data.cart[i].price, row.find('[name = quantity]').val());
                row.find('.cart__row__sum').text(numberFormat(sum, {decimals: 0, thousands_sep: ' '}) + ' ' + shop.currency.name)
                break;
            }
        }
        //shop.validateDelivery();
        shop.updateCartTable(data);
    }

    function changeCount (row, productId, val) {
        // ищем в обьекте корзины нужный товар по id
        for (var key in cart.products) {
            if (cart.products[key].id == productId) {
                // обновляем ему кол-во
                cart.products[key].quantity = parseInt(val);
            }
        }

        timeout = setTimeout(function() {
            cart.push(shop.$srcCart, cart.products, function (data) {
                setTotal(data, row);
                //shop.validateDelivery();
            });
        }, 300);
    }

    shop.$orderTable.on('keyup', '[name = quantity]', function() {
        clearTimeout(timeout);
        var row = $(this).closest('.cart__row');

        if ($(this).val() === '') $(this).val('1');

        // нашли id изменяемого товара
        var productId = $(this).parents('.cart__row').data('id');

        changeCount(row, productId, $(this).val());

        // // ищем в обьекте корзины нужный товар по id
        // for (var key in cart.products) {
        //     if (cart.products[key].id == productId) {
        //         // обновляем ему кол-во
        //         cart.products[key].quantity = parseInt($(this).val());
        //     }
        // }

        // timeout = setTimeout(function() {
        //     cart.push(shop.$srcCart, cart.products, function (data) {
        //         setTotal(data, row);
        //         //shop.validateDelivery();
        //     });
        // }, 300);
    }).on('click', '.plus, .minus', function() {
        clearTimeout(timeout);
        var row = $(this).closest('.cart__row');
        var inp = $(this).parent().find('[name = quantity]');
        var quantity = inp.val();

        if ($(this).hasClass('plus')) quantity ++;
        else if (quantity > 1) quantity --;
        inp.val(quantity);

        // нашли id изменяемого товара
        var productId = $(this).parents('.cart__row').data('id');

        changeCount(row, productId, quantity);
    });
}

shop.delProdInCart = function () {
    shop.$orderTable.on('click', '.cart__row__del div', function() {
        var row = $(this).parents('.cart__row');
        var id = row.data('id');
        row.remove();
        cart.delProduct(id);
        if (cart.products.length == 0) location.reload();
        cart.push(shop.$srcCart, cart.products, function (data) {
            //Cart.calcSumTotal();
            shop.$total.each(function() {
                $(this).text(data.formatTotal + ' ' + shop.currency.name);
            });
        });
    });
}

/*shop.clearAllCart = function () {
    shop.$clearBut.click(function() {
        cart.products = [];
        cart.push(shop.$srcCart, cart.products);
        shop.cartBox.find('.cart-item').each(function() {
            $(this).remove();
        });
        shop.cartBox.find('.button, .all-num, .types-num, .total').hide();
        shop.cartBox.prepend('<div class="empty-cart">Ваша корзина пуста</div>');
    });
}*/

shop.construct = function (data) {
    for (var key in data.cart) {
        var product = new Product(data.cart[key]);
        cart.addProduct(product);
    }
    shop.updateCartTable(data);
    shop.delivType = data.delivery;
    //shop.validateDelivery();
}

shop.constructBoxCart = function (data) {
    for (var key in data.cart) {
        var product = new Product(data.cart[key]);
        cart.addProduct(product);
        shop.addProdInCart(data);
    }
    //shop.cartBox.find('.total .num').text(data.formatTotal + ' ' + shop.currency.name);
}

shop.request = function () {
    cart.pull(shop.$srcCart, function (data) {
        if (shop.$pageOptionCart) {
            shop.construct(data);
        } else {
            shop.constructBoxCart(data);
        }
    });
}

shop.updateQuantity = function () {
    for (var i = 0; i < cart.products.length; ++i) {
        var item = shop.cartBox.find('[data-id='+cart.products[i].id+']');
        item.find('.quantity').val(cart.products[i].quantity);
    }
}

shop.addProdInCart = function (data) {
    if (!shop.$headCart.hasClass('vis')) shop.$headCart.addClass('vis');
    shop.$headCart.find('.head__cart__sum').text(numberFormat(data.total, {decimals: 0, thousands_sep: ' '}) + ' ' + shop.currency.name);
    shop.$headCart.find('.head__cart__count').text(data.totalQuantity);
    //if (shop.$pageOptionCart) shop.updateQuantity();
}

/*shop.hideProdPopup = function() {
    var closeBut = shop.popup.find('.popupAddCart__title__close');
    var bg = shop.popup.find('.popupAddCart__bg');

    closeBut.on('click', function() {
        shop.popup.removeClass('vis').fadeOut(250);
    });

    shop.popup.on('click touchstart', function(event) {
		var $target = $(event.target);
        if (!$target.is(bg) && !bg.find($target).length && !$target.is(closeBut) && !closeBut.find($target).length) closeBut.trigger('click');
    });
}*/

/*shop.addProdPopup = function(data, but) {
    var item = shop.popup.find('.popupAddCart__item');

    item.find('img').attr('src', data.src);
    item.find('.popupAddCart__item__about__title').text(data.name);
    data.size ? item.find('.popupAddCart__item__about__size').show().text(data.size) : item.find('.popupAddCart__item__about__size').hide();
    item.find('.popupAddCart__item__about__price').text(numberFormat(data.price, {decimals: 0, thousands_sep: ' '}) + ' ' + shop.currency.name);

    shop.popup.show(function() {
        $(this).addClass('vis');
        if (shop.$pageOptionItem) but.hide().next('.button').css('display', 'inline-block');
    });
}*/

shop.closeWindow = function () {
    var win = shop.winProduct.parent();

    shop.closeBut.on('click', function(event) {
        shop.winProduct.empty();
        shop.fixCart.removeClass('vis');
        event.preventDefault();
    });

    $(document).on('click touchstart', function(event) {
		var $target = $(event.target);
        if (!$target.is(win) && !win.find($target).length && !$target.is(shop.closeBut) && !shop.closeBut.find($target).length && shop.fixCart.hasClass('vis')) shop.closeBut.trigger('click');
    });
}

shop.constructAddProdWindow = function(item) {
    if ($('body').find('.getWindow').length) $('.mfp-close').trigger('click');

    shop.fixCart.addClass('vis');

    shop.winProduct.html('<img src='+item.src+' alt="" /><div class="addProdPopup__item__title">'+item.name+'</div><div class="addProdPopup__item__amount">Количество: '+item.quantity+'</div><div class="addProdPopup__item__cost">'+item.price+' ₽</div>');
}

shop.buy = function () {
    //shop.cartBox = $('#mm').find('.mm__cart').next();
    $(document).on('click', '.button_incart, .catalog__item__incart', function(event) {
        event.preventDefault();
        var item = $(this);
        
        var product = new Product({
            id: item.data('id'),
            name: item.data('name'),
            price: item.data('price'),
            formatPrice: item.data('format-price'),
            link: item.data('link'),
            // size: item.data('size'),
            src: item.data('src'),
            quantity: item.data('quantity'),
            article: item.data('article'),
        });

        cart.addProduct(product, product.quantity);

        /*if (!shop.cartBox.find('[data-id='+product.id+']').length) {
            shop.addProdInCart(product);
        } else {
            shop.updateQuantity();
        }*/

        cart.push(shop.$srcCart, cart.products, function (data) {
            shop.addProdInCart(data);
            item.hide();
            if (item.hasClass('button_incart')) item.next().css('display', 'inline-block');
            shop.constructAddProdWindow(product);
            // shop.addProdPopup(product, item);
            //shop.cartBox.find('.total .num').text(data.formatTotal + ' ' + shop.currency.name);
        });
    });
}

shop.validateDelivery = function () {
    shop.$delivInput.each(function() {
        var countInCart = cart.countInCart();
        var box = $(this).parent();
        var minDelivery = box.find('[name=minimum]').val();
        var freeDelivery = box.find('[name=after]').val();
        var minCheckbox = box.find('[name=min]');
        var freeCheckbox = box.find('[name=free]');
        
        var hasMinAmount = countInCart >= minDelivery || typeof minDelivery == 'undefined';
        $(this).attr('disabled', !hasMinAmount);
        minCheckbox.prop('checked', hasMinAmount);
        if (!hasMinAmount) {
            $(this).prop('checked', false);
        } else {
            $(this).prop('checked', true);
            shop.setDelivery();
        }
        
        var hasFreeDelivery = countInCart >= freeDelivery || typeof freeDelivery == 'undefined';
        freeCheckbox.prop('checked', hasFreeDelivery);
    });
}

shop.changeDelivery = function() {
    shop.$delivInput.change(function () {
        shop.setDelivery();
    });
}

shop.setDelivery = function() {
    var request = {
        // delivery: shop.$delivInput.val(),
        delivery: shop.delivType
    };
    
    cart.push(shop.$srcCart, cart.products, shop.updateCartTable, request);
}

shop.updateCartTable = function(data) {
    /*if (typeof data.formatDelivery != 'undefined') {
        shop.$delivery.text(data.formatDelivery + ' ' + shop.currency.name);
    } else {
        shop.$delivery.text(shop.$deliveryEmpty.val());
    }*/
    shop.$total.each(function() {
        $(this).text(numberFormat(data.total, {decimals: 0, thousands_sep: ' '}) + ' ' + shop.currency.name);
    });

    shop.$totalQuantity.text(data.totalQuantity);

    var sum = Number(data.total) + Number(shop.deliveryPrice);
    shop.$orderTotal.text(numberFormat(sum, {decimals: 0, thousands_sep: ' '}) + ' ' + shop.currency.name);

    // if (shop.$pageOptionCart) {
    //     var sum = Number(data.total) + Number(shop.deliveryPrice);
    //     shop.$orderTotal.text(numberFormat(sum, {decimals: 0, thousands_sep: ' '}) + ' ' + shop.currency.name);
    // }
}

shop.order = function () {
    var delivery = $('[name = delivery]');
    var pay = $('[name = pay]');
    var payonline = $('#pay1');
    var payofline = $('#pay2');
    var address = $('#address');
    var infodeliv = $('#infoDeliv');
    var infopay = $('#infopay');
    var tabs = $('[name = delivery]').parents('.switch');
    var payBut = $('#payBut');
    var orderBut = $('#orderBut');

    var orderForm = new CheckForms($('#orderForm'), '.button_submit');

    delivery.each(function() {
        if ($(this).is('input:checked')) {
            Number($(this).data('price')) ? infodeliv.text($(this).data('price') + ' ' + shop.currency.name) : infodeliv.text($(this).data('str'));
        }
    });

    pay.each(function() {
        if ($(this).is('input:checked')) infopay.text($(this).data('str'));
    });

    delivery.on('change', function() {
        shop.deliveryPrice = Number($(this).data('price'));
        if ($(this).attr('id') == 'delivery1') {
            infodeliv.text($(this).data('str'));
            address.removeClass('requiredField errorField emptyField');
            payofline.prop('disabled', false);
        } else {
            address.addClass('requiredField');
            shop.deliveryPrice ? infodeliv.text($(this).data('price') + ' ' + shop.currency.name) : infodeliv.text($(this).data('str'));
            payonline.trigger('click');
            payofline.prop('disabled', true);
        }

        shop.delivType = $(this).attr('id');

        orderForm.checkEmpty();
        shop.setDelivery();
    });

    tabs.find('.switch__radio').on('change', function() {
        var index = $(this).index('.switch__radio') + 1;
        var item = tabs.find('.switch__item:nth-of-type('+index+')');
        item.find('.switch__item__radio:first-of-type').trigger('click');
    });

    pay.on('change', function() {
        if ($(this).attr('id') == 'pay2') {
            payBut.hide();
            orderBut.show();
        } else {
            payBut.show();
            orderBut.hide();
        }
        //infopay.text($(this).data('str'));
    });
}

shop.valueTest = function () {
    shop.$orderTable.on('keypress mouseup', '[name = quantity]', function(e) {
        e = e || event;

        if (e.ctrlKey || e.altKey || e.metaKey) return;

        var chr = shop.getChar(e);

        if (chr == null) return;

        if (chr < '1' || chr > '9') {
            return false;
        }
    });
}

shop.getChar = function(event) {
    if (event.which == null) {
        if (event.keyCode < 32) return null;
        return String.fromCharCode(event.keyCode) // IE
    }

    if (event.which != 0 && event.charCode != 0) {
        if (event.which < 32) return null;
        return String.fromCharCode(event.which) // остальные
    }

    return null; // специальная клавиша
}

function numberFormat (number, cfg) {
	function obj_merge(obj_first, obj_second){
		var obj_return = {};
		for (key in obj_first){
			if (typeof obj_second[key] !== 'undefined') obj_return[key] = obj_second[key];
			else obj_return[key] = obj_first[key];
		}
		return obj_return;
	}
	function thousands_sep(num, sep){
		if (num.length <= 3) return num;
		var count = num.length; var num_parser = ''; var count_digits = 0;
		for (var p = (count - 1); p >= 0; p--){
			var num_digit = num.substr(p, 1);
			if (count_digits % 3 == 0 && count_digits != 0 && !isNaN(parseFloat(num_digit))) num_parser = sep + num_parser;
			num_parser = num_digit + num_parser;
			count_digits++;
		}
		return num_parser;
	}
	if (typeof number !== 'number'){
		number = parseFloat(number);
		if (isNaN(number)) return false;
	}
	var cfg_default = {before: '', after: '', decimals: 2, dec_point: '.', thousands_sep: ','};
	if (cfg && typeof cfg === 'object'){ cfg = obj_merge(cfg_default, cfg); } else cfg = cfg_default;
	number = number.toFixed(cfg.decimals);
	if (number.indexOf('.') != -1){
		var number_arr = number.split('.');
		var number = thousands_sep(number_arr[0], cfg.thousands_sep) + cfg.dec_point + number_arr[1];
	} else var number = thousands_sep(number, cfg.thousands_sep);
	return cfg.before + number + cfg.after;
}